<template>
    <div class="row justify-content-center m0">
        <div class="col-12 col-lg-3 content-body">
            <div class="row">
                Member Area
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MemberArea',
}
</script>